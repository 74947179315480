import { useState } from "react";
import Modal from "../components/Modal";
import { GetInTouch } from "../components/GetInTouch";

function ElectricExplorerFreeCharging() {
  const model = "Electric Explorer с 5 години безплатно зареждане*";
  const copy = "p.tabakova@motopfohe.bg";
  document.title = "NEW ALL-ELECTRIC EXPLORER";
  const [showModal, setShowModal] = useState(false);
  const [zapitvane, setZapitvane] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setZapitvane(false);
  };
  return (
    <div>
      <img
        src="/images/electric-explorer-free-charging/saleFord_bg_dekstop_explorer.jpg"
        alt=""
        className="hidden mt-1 lg:block"
      />
      <img
        src="/images/electric-explorer-free-charging/explorer_bg_mobile.jpg"
        alt=""
        className="mt-1 lg:hidden"
      />
      <section className="flex flex-col items-center px-4 py-12 lg:justify-center lg:p-16 lg:flex-row bg-gray-50">
        <a
          href="https://ford.bg/cars/new-explorer"
          target="_blank"
          rel="noreferrer"
        >
          <button className="px-12 py-2 mx-8 border rounded-full border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white">
            Виж повече за модела
          </button>
        </a>
        <a href="http://sale.ford.bg/?model=E-Explorer">
          <button className="px-12 py-2 mx-8 mt-8 border rounded-full lg:mt-auto border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white">
            Виж налични автомобили
          </button>
        </a>
        <button
          className="px-12 py-2 mx-8 mt-8 border rounded-full lg:mt-auto border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white"
          onClick={() => {
            setShowModal(true);
            setZapitvane(true);
            window.scrollTo(0, 0);
          }}
        >
          Свържи се с търговец
        </button>
      </section>
      {showModal && zapitvane && (
        <Modal handleClose={handleClose}>
          <GetInTouch model={model} copy={copy} />
        </Modal>
      )}
    </div>
  );
}

export default ElectricExplorerFreeCharging;
